<template>
	<modal-layout title="Activate Wallet">
		<form class="flex flex-col gap-4" @submit.prevent="activateWallet">
			<p class="text-xs text-[#575A65]">We require your BVN to be able to activate your wallet and create an account for you.</p>
			<div class="form-group">
				<label class="form-label">Bvn</label>
				<input
					v-model.trim="bvn"
					required
					type="text"
					class="form-control"
					/>
			</div>

			<div class="form-group">
				<label class="form-label">Provider</label>
				<select class="custom-select" v-model="provider">
					<option value="FlutterwaveNubanProvider">Flutterwave</option>
					<option value="PayedNubanProvider">Payed</option>
				</select>
			</div>

			<div class="w-100">
				<button
					:disabled="loading || !enableButton"
					type="submit"
					class="btn btn-primary w-100"
					>
					{{ loading ? 'Processing...' : 'Activate wallet' }}
				</button>
			</div>
		</form>
	</modal-layout>
</template>

<script setup>
import {onMounted, computed} from 'vue'
import modalLayout from '@/layouts/core/modal.vue'
import {useActivateWallet} from '@/composables/backend/wallet'

const { bvn,provider, loading, activateWallet } = useActivateWallet()
onMounted(() => {
	bvn.value = ''
	bvn.provider = 'FlutterwaveNubanProvider'
})

const enableButton = computed(() => {
	return provider.value && bvn.value.length >= 11 ? true : false
})
</script>